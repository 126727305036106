<template>
    <div>
        <router-view :productInfo="productInfo"></router-view>
    </div>
</template>

<script>
    export default {
        name: 'ProductPageHero',
        components: {
        },
        data() {
            return {
                productInfo: {}
            }
        },
        watch: {

        },
        beforeMount() {
            this.productInfo = this.$root.productInfo;
        },
        mounted() {

        },
        methods: {

        }
    }
</script>