<template>
    <div class="hero hero-product-detail w-full relative mb-54px">
        <div class="flex items-center justify-center w-full min-h-480px px-5 relative">
            <picture v-if="currentProduct && currentProduct.productBannerImage.length" class="bg-center absolute bg-cover bg-no-repeat left-0 top-0 w-full h-full z-0 hero-picture" :style="currentBackgroundStyle">
                <img class="opacity-0 invisible w-full block absolute left-0 top-0" :src="currentProduct.productBannerImage[0].url" :alt="currentProduct.title" />
            </picture>
            <div class="container mx-auto">
                <div class="flex items-end md:min-h-560px relative z-20">
                    <div class="w-full text-white py-30">
                        <div class="flex flex-row h-full items-end">
                            <div class="w-6/12" v-if="productInfo">
                                <div class="mr-auto max-w-260px -mb-54px">
                                    <div class="bg-white shadow-figure2">
                                        <ul class="text-shuttle text-13px">
                                            <li class="p-4 border-b border-blackhaze">
                                                <strong class="font-bold text-lochmara text-base">{{'Product Variations'|t}}</strong>
                                            </li>
                                            <li class="p-4 border-b border-blackhaze last:border-b-0" :class="{'bg-porcelain2': product.slug === currentProduct.slug}" v-for="product in this.productInfo.products" :key="product.slug">
                                                <router-link :to="getProductLink(product)" class="font-bold text-shuttle text-13px">{{product.title}}</router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="w-6/12" v-if="currentProduct">
                                <div class="ml-auto max-w-355px -mb-54px">
                                    <div class="bg-white p-6 shadow-figure2">
                                        <div class="flex flex-col">
                                            <div class="mb-5">
                                                <h1 class="block text-28px text-shark font-bold">{{currentProduct.title}}</h1>
                                                <span class="block text-xs text-shuttle">{{currentProduct.excerptText}}</span>
                                            </div>
                                            <!-- Only show the toggle if sizes are defined AND colors are available. -->
                                            <div class="mb-5" v-if="currentProduct.availableProductSizes && currentProduct.availableProductSizes.length && (currentProduct.availableProductStains.length || currentProduct.availableProductColors.length)">
                                                <span class="block text-shuttle mb-3">
                                                    <div class="flex -mx-1">
                                                        <div class="mx-1">
                                                            <button class="bg-porcelain2 rounded-full w-43px h-23px flex items-center" @click="toggleViewSizes">
                                                                <span class="relative mx-2px w-full h-full">
                                                                    <span :class="displayingSizes ? 'translate-x-full' : ''" class="absolute inset-y-0 my-auto block bg-allports rounded-full w-19px h-19px transform transition-transform duration-250 accelerate hover:bg-orient"></span>
                                                                </span>
                                                            </button>
                                                        </div>
                                                        <div class="mx-1">
                                                            <span class="text-shark font-bold text-xs" v-if="displayingSizes">{{'View colors'|t}}</span><span class="text-shark font-bold text-xs" v-else>{{"View sizes"|t}}</span>
                                                        </div>
                                                    </div>
                                                </span>
                                                <hr class="-mx-6 border-iron2" />
                                            </div>
                                            <div v-if="!displayingSizes">
                                                <div class="mb-5" v-if="currentProduct.availableProductColors.length">
                                                    <span class="block text-shuttle mb-1" v-if="currentColor"><strong class="text-shark font-bold">{{'Color'|t}}: </strong>{{currentColor.title}}</span>

                                                    <ul class="flex flex-wrap -mx-1 pd-color-picker">
                                                        <li class="m-1" v-for="color in currentProduct.availableProductColors" :key="color.uid">
                                                            <router-link :to="getSwatchLink(currentProduct, color)" class="block w-30px h-30px p-2 rounded-full cursor-pointer relative pd-color-picker__pallete" :class="{'active': currentColor.uid === color.uid}" :style="{backgroundColor: color.color}"></router-link>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div v-if="currentProduct.availableProductStains.length">
                                                    <div class="mb-5">
                                                        <span class="block text-shuttle mb-1"><strong class="text-shark font-bold">{{"Stain"|t}}</strong></span>
                                                        <ul class="flex flex-wrap -mx-1 pd-color-picker">
                                                            <li class="m-1" v-for="stain in currentProduct.availableProductStains" :key="stain.uid">
                                                                <router-link :to="getSwatchLink(currentProduct, stain)" class="block w-30px h-30px p-2 rounded-full cursor-pointer relative pd-color-picker__pallete" :class="{'active': currentColor.uid === stain.uid}" :style="{backgroundColor: stain.color}"></router-link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div v-if="currentProduct.availablePrimed">
                                                    <div class="mb-5">
                                                        <span class="block text-shuttle mb-1"><strong class="text-shark font-bold">{{"Primed"|t}}</strong></span>
                                                        <ul class="flex flex-wrap -mx-1 pd-color-picker">
                                                            <li class="m-1">
                                                                <router-link :to="getSwatchLink(currentProduct, primedColor)" class="block w-30px h-30px p-2 rounded-full cursor-pointer relative pd-color-picker__pallete" :class="{'active': currentColor.uid === primedColor.uid}" :style="{backgroundColor: primedColor.color}"></router-link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <div class="block text-shuttle mb-2">
                                                    <ul class="flex flex-wrap -mx-2 pd-size-picker">
                                                        <li class="px-2 w-6/12 mb-10px" v-for="(size, index) in currentProduct.availableProductSizes">
                                                            <div class="relative cursor-pointer pd-size-picker__item">
                                                                <input :id="'size-' + index" type="radio" name="pd-sizes" class="absolute inset-0 left-0 top-0 w-full h-full opacity-0 cursor-pointer -z-1">
                                                                <label :for="'size-' + index" class="bg-porcelain rounded text-shuttle px-3 py-2 text-xs flex items-center justify-center leading-none cursor-pointer transition hover:bg-porcelain2">{{size.productSize}}</label>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="mt-3" v-if="productInfo.productPageCtaLink">
                                                <a :href="productInfo.productPageCtaLink.url" :target="productInfo.productPageCtaLink.target" class="w-full cursor-pointer flex items-center justify-center bg-lochmara font-bold text-xs text-white py-5 px-8">{{productInfo.productPageCtaLink.text}}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    const primedColor = {
        title: 'Primed',
        slug: 'primed',
        typeHandle: 'primed',
        uid: 'primed',
        color: '#a1a79c'
    }
    export default {
        name: 'HeroProduct',
        components: {
        },
        data: () => {
            return {
                primedColor: primedColor,
                displayingSizes: false,
                currentColor: null,
                currentProduct: null
            }
        },
        watch: {
            '$route': function(newVal) {
                this.setCurrentProduct();
                this.setCurrentColor();
            },
        },
        beforeMount() {
            this.setCurrentProduct();
            this.setCurrentColor();
        },
        methods: {
            toggleViewSizes() {
                this.displayingSizes = !this.displayingSizes;
            },
            getProductLink(product) {
                return {
                    name: this.$route.name ?? 'product',
                    params: {productSlug: product.slug}
                }
            },
            /**
             * Generates a link for a color swatch such that the current color is maintained on the next product
             */
            getSwatchLink(product, swatch) {
                let routeName = null
                let params = {productSlug: product.slug}
                switch(swatch.typeHandle) {
                    case 'primed':
                        routeName = 'productPrimed'
                        break
                    case 'productColor':
                        routeName = 'productColor'
                        params.colorSlug = swatch.slug
                        break
                    case 'productStain':
                        routeName = 'productStain'
                        params.colorSlug = swatch.slug
                        break
                }
                return {
                    name: routeName,
                    params: params
                }
            },
            /**
             * Updates the current product based on the requested one
             */
            setCurrentProduct() {
                const productSlug = this.$route.params.productSlug;
                const products = this.productInfo.products;
                if(productSlug) {
                    const products = this.productInfo.products;
                    for (let index = 0; index < products.length; index++) {
                        if (products[index].slug === this.$route.params.productSlug) {
                            this.currentProduct = products[index];
                            return
                        }
                    }
                }
                this.currentProduct = products[0];
            },
            /**
             * Updates the current color based on the requested one and availability on the current product
             */
            setCurrentColor() {
                const colors = this.currentProduct.availableProductColors
                const stains = this.currentProduct.availableProductStains
                const availablePrimed = this.currentProduct.availablePrimed

                // If there are colors available and the user requested a color, match the slug to the available colors
                if (colors.length && this.$route.name === 'productColor') {
                    for (let index = 0; index < colors.length; index++) {
                        if (colors[index].slug === this.$route.params.colorSlug) {
                            this.currentColor = colors[index];
                            return
                        }
                    }
                    // Requested color not found, use the first color available and update the route
                    if (!this.currentColor) {
                        this.currentColor = colors[0];
                        this.$router.replace({name: 'productColor', params: {productSlug: this.$route.params.productSlug, colorSlug: colors[0].slug}})
                    }
                // If there are stains available and the user requested a stain, match the slug to the available stains
                } else if (stains.length && this.$route.name === 'productStain') {
                    for (let index = 0; index < stains.length; index++) {
                        if (stains[index].slug === this.$route.params.colorSlug) {
                            this.currentColor = stains[index];
                            return
                        }
                    }
                    // Requested stain not found, use the first stain available and update the route
                    if (!this.currentColor) {
                        this.currentColor = stains[0];
                        this.$router.replace({name: 'productStain', params: {productSlug: this.$route.params.productSlug, colorSlug: stains[0].slug}})
                    }
                // If the product is available primed and the user requested primed, set it to primed!
                } else if (availablePrimed && this.$route.name === 'productPrimed') {
                    this.currentColor = primedColor
                } else {
                    // Something wacky happened, so we're going to show the next available data for the product (Colors -> stains -> primed -> sizes)
                    if (colors.length) {
                        this.currentColor = colors[0];
                        this.$router.replace({name: 'productColor', params: {productSlug: this.$route.params.productSlug, colorSlug: colors[0].slug}})
                    } else if(stains.length) {
                        this.currentColor = stains[0];
                        this.$router.replace({name: 'productStain', params: {productSlug: this.$route.params.productSlug, colorSlug: stains[0].slug}})
                    } else if (availablePrimed) {
                        this.currentColor = primedColor;
                        this.$router.replace({name: 'productPrimed', params: {productSlug: this.$route.params.productSlug}})
                    } else if (this.currentProduct.availableProductSizes && this.currentProduct.availableProductSizes.length) {
                        this.displayingSizes = true;
                    } else {
                        console.error('Something went wrong.')
                    }
                }
            }
        },

        computed: {
            /**
             * Computes the background styling for the current product configuration
             * @returns {{background: string}|null}
             */
            currentBackgroundStyle: function() {
                if (!this.currentProduct) {
                    return null;
                }
                if (!this.currentColor) {
                    return {
                        background: 'url(' + this.currentProduct.productBannerImage[0].url + ') center/cover no-repeat',
                    }
                }
                return {
                    background: this.currentColor.color + ' url(' + this.currentProduct.productBannerImage[0].url + ') center/cover no-repeat',
                }
            }
        },

        props: [
            'productInfo'
        ]
    }
</script>

<style scoped type="text/css">
    .hero-picture {
        transition: background-color 0.1s ease-in;
        background-size: cover;
    }
</style>
